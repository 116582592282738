define("discourse/plugins/discourse-staff-alias/discourse/connectors/revision-user-details-after/aliased-staff-user-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    shouldRender(_, component) {
      return component.siteSettings.staff_alias_enabled;
    }
  };
});