define("discourse/plugins/discourse-staff-alias/discourse/templates/connectors/composer-action-after/reply-as-staff-alias-icon", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if model.isReplyAsStaffAlias}}
    <span class="reply-as-staff-alias-icon">{{d-icon "user-secret"}}</span>
  {{/if}}
  */
  {
    "id": "uCmxLKRX",
    "block": "[[[41,[30,0,[\"model\",\"isReplyAsStaffAlias\"]],[[[1,\"  \"],[10,1],[14,0,\"reply-as-staff-alias-icon\"],[12],[1,[28,[35,1],[\"user-secret\"],null]],[13],[1,\"\\n\"]],[]],null],[1,[28,[32,0],[\"[[\\\"The `model` property path was used in the `discourse/plugins/discourse-staff-alias/discourse/templates/connectors/composer-action-after/reply-as-staff-alias-icon.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.model}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"if\",\"d-icon\"]]",
    "moduleName": "discourse/plugins/discourse-staff-alias/discourse/templates/connectors/composer-action-after/reply-as-staff-alias-icon.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});